import {
    Avatar,
    Box,
    Card,
    CardContent, CardMedia,
    Container,
    Divider, IconButton, Link,
    Rating,
    Stack, SvgIcon, Tooltip,
    Typography,
    Unstable_Grid2 as Grid
} from '@mui/material';
import HeartIcon from "@untitled-ui/icons-react/build/esm/Heart";
import Users01Icon from "@untitled-ui/icons-react/build/esm/Users01";
import {formatDistanceToNowStrict} from "date-fns";
import debug from "debug";
import numeral from "numeral";
import * as React from "react";
import {useCallback, useEffect, useState} from "react";
import {useMounted} from "../../hooks/use-mounted";
import {servicesFeedApi} from "../../api/servicesFeed";
import {RouterLink} from "../../components/router-link";

const logger = debug("[Home reviews]")


const labels1: { [index: string]: string } = {
    0: '',
    1: 'Got more problems than benefits',
    2: 'I`ve got couple major problems',
    3: 'Acceptable',
    4: 'Good, but I`ve got a couple problems',
    5: 'Perfect',
};

function getPostSharedLink(url, postid) {
    return process.env.REACT_APP_HOST_P + "/specialist/" + url + "?postId=" + postid;
}


function getReviewerSharedLink(url) {
    return process.env.REACT_APP_HOST_P + "/specialist/" + url;
}

export const useReviews = () => {
    const [reviews, setReviews] = useState([]);
    const isMounted = useMounted();

    const handleReviewsGet = useCallback(async () => {
        const response = await servicesFeedApi.getLastPostsReviews(6);
        const lastReviews = [];
        response.forEach((doc) => {
            const post = doc.data();
            logger(post);
            lastReviews.push({
                createdAt: post.createdAt.toDate(),
                author: post.authorName,
                reviewer: post.customerName,
                reviewerAvatar: post.customerAvatar,
                message: post.customerFeedback,
                rating: post.rating,
                commentsCount: post.comments.length,
                postUrl: getPostSharedLink(post.userId, doc.id),
                reviewerUrl: getReviewerSharedLink(post.customerProfilePage),
                image: post.photos[0],
                likes: (post.likes || []).length
            })
        });
        if (isMounted()) {
            setReviews(lastReviews);
        }
    }, [isMounted]);

    useEffect(() => {
            handleReviewsGet();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []);

    return [reviews, handleReviewsGet];
};

export const HomeReviews2 = () => {
    const [reviews, handleReviewsGet] = useReviews();

    return (
        <div>
            <Container maxWidth="lg">
                <Stack
                    spacing={8}
                    sx={{py: '120px'}}
                >
                    <Stack spacing={2}>
                        <Typography
                            align="center"
                            variant="h3"
                        >
                            We strive to be useful and people are grateful to us.
                        </Typography>
                        <Typography
                            align="center"
                            color="text.secondary"
                            variant="subtitle1"
                        >
                            Every specialist and performer undergoes a thorough check, so we guarantee customers quality
                            and
                            deadlines.<br/> Each client can leave a review, and you can see them
                        </Typography>
                    </Stack>
                    <Grid
                        container
                        spacing={3}
                    >
                        {reviews.map((review, index) => {
                            return (
                                <Grid
                                    key={index}
                                    xs={12}
                                    md={4}
                                >
                                    <Card>
                                        <Box sx={{p: 2}}>
                                            <CardMedia
                                                image={review.image}
                                                sx={{
                                                    backgroundColor: 'neutral.50',
                                                    height: 150
                                                }}
                                            />
                                            <Box
                                                sx={{
                                                    alignItems: 'center',
                                                    display: 'flex',
                                                    mt: 2
                                                }}
                                            >
                                                <Avatar src={review.reviewerAvatar}/>
                                                <Box sx={{ml: 2}}>
                                                    <Link
                                                        color="text.primary"
                                                        variant="h6"
                                                        href={review.reviewerUrl}
                                                    >
                                                        {review.reviewer}
                                                    </Link>
                                                    <Typography
                                                        color="text.secondary"
                                                        variant="body2"
                                                    >
                                                        to
                                                        {' '}
                                                        <Link
                                                            color="text.primary"
                                                            variant="subtitle2"
                                                            href={review.postUrl}
                                                        >
                                                            {review.author}
                                                        </Link>
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box
                                            sx={{
                                                pb: 2,
                                                px: 3
                                            }}
                                        >
                                            <Link
                                                color="text.primary"
                                                variant="h6"
                                                href={review.reviewerUrl}
                                            >
                                                {labels1[review.rating]}
                                            </Link>
                                            <Typography
                                                color="text.secondary"
                                                variant="body2"
                                            >
                                                <div dangerouslySetInnerHTML={{__html: review.message}}/>
                                            </Typography>
                                        </Box>

                                        <Divider/>
                                        <Box
                                            sx={{
                                                alignItems: 'center',
                                                display: 'flex',
                                                pl: 2,
                                                pr: 3,
                                                py: 2
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    alignItems: 'center',
                                                    display: 'flex'
                                                }}
                                            >
                                                <Tooltip title="Unlike">
                                                    <IconButton>
                                                        <SvgIcon
                                                            sx={{
                                                                color: 'error.main',
                                                                '& path': {
                                                                    fill: (theme) => {
                                                                        if (review.likes > 0) {
                                                                            return theme.palette.error.main
                                                                        } else {
                                                                            return theme.palette.grey.main
                                                                        }
                                                                    },
                                                                    fillOpacity: 1
                                                                }
                                                            }}
                                                        >
                                                            <HeartIcon/>
                                                        </SvgIcon>
                                                    </IconButton>
                                                </Tooltip>
                                                <Typography
                                                    color="text.secondary"
                                                    variant="subtitle2"
                                                >
                                                    {review.likes}
                                                </Typography>
                                            </Box>
                                            <Box
                                                sx={{
                                                    alignItems: 'center',
                                                    display: 'flex',
                                                    ml: 2
                                                }}
                                            >
                                                <SvgIcon>
                                                    <Users01Icon/>
                                                </SvgIcon>
                                                <Typography
                                                    color="text.secondary"
                                                    sx={{ml: 1}}
                                                    variant="subtitle2"
                                                >
                                                    {review.commentsCount}
                                                </Typography>
                                            </Box>
                                            <Box sx={{flexGrow: 1}}/>
                                            <Rating
                                                readOnly
                                                size="small"
                                                value={review.rating}
                                            />
                                        </Box>
                                    </Card>
                                </Grid>
                            );
                        })}
                    </Grid>
                </Stack>
            </Container>
        </div>
    );
}
